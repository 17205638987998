import api from '@/utils/api'

const survicatePlugin = async (store) => {
  const setAxiosAuth = () => {
    const token = store.state.auth?.token || ''

    api.defaults.headers.common.Authorization = token
  }

  const fetchMerchantDetails = async (id) => {
    const response = await api.post('merchants/merchant', { merchant_id: id })

    return response.data?.data?.merchant_data || {}
  }

  const fetchDashboardUserEmailandType = async (dashboardUserId) => {
    const response = await api.post(`merchants/dashboard-user`, {
      dashboard_user_id: dashboardUserId
    })

    return response.data?.dashboard_user
  }

  const setSurvicateTraits = (payload) => {
    if (process.env.VUE_APP_NODE_ENV === 'development') {
      console.log('SET SURVICATE TRAITS::', payload)
    }

    if (window._sva) {
      window._sva.setVisitorTraits(payload)
      window._sva.retarget()
    }
  }

  const onLoad = async () => {
    const { merchant_id: merchantId, id: dashboardUserId } = store.state?.auth?.user || {}

    let dashboardUser = null

    if (dashboardUserId) {
      dashboardUser = await fetchDashboardUserEmailandType(dashboardUserId)
    }

    if (merchantId && dashboardUser) {
      const merchant = await fetchMerchantDetails(merchantId)

      setSurvicateTraits({
        merchant_id: merchant.id,
        merchant_risk_tier: merchant.risk_tier || '',
        category_name: merchant.category || '',
        merchant_created_at: merchant.created_at,
        user_role: dashboardUser.user_type,
        email: dashboardUser.email
      })
    }
  }

  setAxiosAuth()

  if (api.defaults.headers.common.Authorization) {
    onLoad()
  }

  store.subscribe(({ type, payload }) => {
    if (type === 'auth/setAuthState') {
      setAxiosAuth()
      onLoad()
    }

    if (type === 'stores/updateStores') {
      setSurvicateTraits({
        store_ids: (payload || []).map(({ id }) => id)
      })
    }

    if (type === 'events/SET_SURVICATE_DETAILS') {
      setSurvicateTraits(payload)
    }

    if (type === 'applications/updateCurrentApplication') {
      setSurvicateTraits({
        amount_total: (payload.products || []).reduce((acc, { price }) => acc + parseFloat(price), 0),
        delivery_fee: parseFloat(payload.application?.shipping_cost || 0),
        number_of_items: payload.products?.length || 0,
        lease_id: payload.lease_details_v2?.id || ''
      })
    }
  })
}

export { survicatePlugin }
